.products {
  &__main {
    position: relative;
    z-index: 10;
  }

  @include media-breakpoint-down(md) {
    .task-card__inner {
      border: 1px solid var(--bs-space-gray-200);
    }
  }
}
