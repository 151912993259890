@each $name, $color in $theme-colors {
  .#{'bg-' + $name} {
    background-color: var(--bs-#{$name}) !important;
  }
}

@each $name, $color in $colors {
  .#{'bg-color-' + $name} {
    background-color: var(--bs-#{$name}) !important;
  }
}

@each $name, $color in $grays {
  .#{'bg-gray-' + $name} {
    background-color: var(--bs-gray-#{$name}) !important;
  }
}

@each $name, $color in $space-grays {
  .#{'bg-space-gray-' + $name} {
    background-color: var(--bs-space-gray-#{$name}) !important;
  }
}

@each $name, $color in map-merge($trash-types, $trash-types) {
  .#{'bg-trash-' + to-lower-case($name)} {
    background-color: var(--bs-trash-#{to-lower-case($name)}) !important;
  }
}
@each $name, $color in map-merge($trash-types, $trash-types-old) {
  .#{'bg-trash-' + to-lower-case($name)} {
    background-color: var(--bs-trash-#{to-lower-case($name)}) !important;
  }
}

//@each $name, $value in $trash-types {
//  .calendar-card-#{$name} {
//    &__icon::before {
//      background-color: $value;
//    }

//    .collection-type {
//      color: $value;
//    }
//  }
//}

//@each $name, $value in $trash-types-old {
//  .calendar-card-#{$name} {
//    &__icon::before {
//      background-color: $value;
//    }

//    .collection-type {
//      color: $value;
//    }
//  }
//}
