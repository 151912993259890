app-top-task-card {
  display: contents;

  fstr-link {
    display: contents;
  }
}

.task-card {
  --task-card-graphic-size: 95px;
  display: flex;
  width: 100%;

  &__inner {
    //  padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__text {
    line-height: 2;
  }

  &__info {
    &__title {
      font-family:
        'Open Sans',
        'Verdena',
        sans serif;
      font-weight: 700;
      font-size: 18px;
      color: var(--bs-secondary);
      margin: 0;
      line-height: 1.5rem;
    }
  }
  &__button {
    @include abs(null, 0, 0, null);
  }

  &__visual {
    min-width: var(--task-card-graphic-size, 100px);
    max-width: var(--task-card-graphic-size, 100px);
  }

  &__image {
    aspect-ratio: 1/1;

    img {
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
      background: var(--bs-secondary-100);
      padding: 1px;
    }
  }
}

.icon-layered {
  position: relative;

  &::before {
    content: '';
    @include abs(0);
    margin: auto;
    width: 100%;
    background-color: var(--bs-space-gray-100);
    border-radius: 100px;
    aspect-ratio: 1/1;
  }

  .icon {
    width: 65%;
    margin: auto;
  }
}

.icon-100-w {
  width: 100%;
  aspect-ratio: 1/1;
}
