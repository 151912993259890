.card {
  --card-padding-x: #{spacer(4)};
  --card-padding-y: #{spacer(4)};

  background-color: var(--bs-light);
  border-radius: $border-radius-lg;
  line-height: 1.2;
  padding: var(--card-padding-y) var(--card-padding-x);

  @include media-breakpoint-down(xs) {
    --card-padding-x: #{spacer(3)};
    --card-padding-y: #{spacer(3)};
  }

  .card-negative-inset-x {
    margin-left: calc(var(--card-padding-x) * -1);
    margin-right: calc(var(--card-padding-x) * -1);
  }
  .card-negative-inset-b {
    margin-bottom: calc(var(--card-padding-y) * -1);
  }

  .px-card {
    padding-left: var(--card-padding-x) !important;
    padding-right: var(--card-padding-x) !important;
  }
}
