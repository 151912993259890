.stepper-list {
  display: flex;
  width: 100%;

  &__item {
    flex: 1;
  }
}

.stepper-button {
  width: 100%;

  &__figure__line {
    width: 100%;
    height: 0.5em;
    display: block;
    background: var(--bs-space-gray-200);
    min-width: 10px;
  }

  &.is--active,
  &.is--finished {
    .stepper-button__figure__line {
      background: var(--bs-secondary);
    }
  }
}

.fieldset__footer {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
