.accordion {
  &__item {
    .accordion-step {
      border-top: 1px solid var(--bs-secondary);
    }

    &:first-child .accordion-step {
      border-top: 0px;
    }
  }
}
