// Imports
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap');

// Bootstrap
$default-font-size: 16px;
$rfs-minimum-font-size: rem($default-font-size); // always use rem unit
$rfs-base-font-size: rem($default-font-size); // always use rem unit
$transition-base: all 0.15s ease-in-out;
$font-size-base: strip-units($default-font-size); // overwrites default bootstrap variable with stripped font-size
$line-height-base: 1.9;

$body-color: gray-color(900);

$link-color: theme-color(secondary);
$link-decoration: underline;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  jumbo-xl: 192,
  jumbo: 82px,
  1: 44px,
  2: 36px,
  3: 33px,
  4: 28px,
  5: 24px,
  6: 18px,
  body: 16px,
  body-xxxs: 12px,
  body-xxs: 14px,
  body-xs: 16px,
  body-sm: 19px,
  body-md: 22px,
  body-lg: 28px,
  body-xl: 34px,
  quote: 24px,
);

// Headings
@import url('https://fonts.googleapis.com/css2?family=Crete+Round:ital@0;1&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

$font-family-sans-serif: 'Open Sans', Verdana, sans-serif;
$font-family-serif: 'Crete Round', Verdana, sans-serif;

$headings-font-family: $font-family-serif;
$headings-font-weight: bold;
$headings-line-height: 1.2;
$headings-color: #000;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 500;

$lead-font-size: map-get($font-sizes, body-sm);
$lead-font-weight: 600;

$text-muted: theme-color(muted);

// Other
//? Put own extra variables here
