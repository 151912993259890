.glide__arrows {
  @include abs(0);

  align-items: center;
  justify-content: space-between;
  pointer-events: none;
}

.content-slider__slider {
  .glide__arrows {
    display: flex !important;
  }
}

div[data-glide-dir] {
  pointer-events: initial;

  @include media-breakpoint-down(md) {
    display: none !important;
  }

  .glide__arrow__button {
    box-shadow: 0px 4px 24px rgba(37, 75, 138, 0.06),
      0px 2px 4px rgba(0, 0, 0, 0.04);
  }
}

div[data-glide-dir='<'] {
  margin-left: calc(-3em - 30px);
}

div[data-glide-dir='>'] {
  margin-right: calc(-3em - 30px);
  text-align: end;
}