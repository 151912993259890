table {
  margin-top: rem(20px);
  margin-bottom: map-get($spacers, 6);
  font-size: rem(14px);
  width: 100%;
  height: auto;

  @include media-breakpoint-down(md) {
    width: calc(100% + #{$grid-gutter-width});
    margin-right: $grid-gutter-width;
  }

  overflow: hidden;
  overflow-x: auto;
  display: flex;

  tbody {
    width: 100%;
    display: table;
  }

  th {
    background: theme-color(secondary);
    color: white;
    font-weight: bold;
    text-align: left;
    padding: 0.7rem 0.5rem;
  }

  tr td:not(:last-child) {
    border-right: 1px solid white;
  }

  tr th:not(:last-child) {
    border-right: 1px solid white;
  }

  tr td {
    padding: map-get($spacers, 2);
    min-width: 180px;
    padding-bottom: 2em;
    line-height: 200%;
    font-size: 1rem;
    font-weight: 100;

    p {
      margin-bottom: 0px !important;
    }

    &.fr-thick {
      font-weight: bold;
    }
    &.fr-highlighted {
      border: 2px solid theme-color(primary);
    }
  }

  tr {
    &:nth-child(even) {
      background: theme-color(secondary-100);
    }

    &:nth-child(odd) {
      background: space-gray-color(100);
    }
  }
}

@include media-breakpoint-down(sm) {
  table.responsive-table {
    tbody {
      display: block;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
    }
    td {
      display: block;
      width: 50%;
      min-width: 0;

      &:not(:last-child) {
        border-bottom: 1px solid white;
      }
    }
  }
}
