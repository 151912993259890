.file-upload {
  background-color: white;
  border: 2px dashed var(--bs-gray-300);
  border-radius: 5px;
  padding: 20px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 130px;

  .file-name-preview-label {
    word-break: break-all;
  }

  &.is--drag-over {
    background: var(--bs-primary-200);
    border: 2px solid var(--bs-primary);
  }
  &.is--not-empty {
    // background: var(--bs-primary-200);
    border: 1px solid var(--bs-primary);
    //justify-content: flex-start;
  }

  .custom-field--invalid & {
    border-color: var(--mat-warn-color, #b71721);
    background-color: lighten(theme-color(danger), 55%);
  }

  &__file {
    display: flex;
    align-items: center;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
      text-align: center;

      &__actions {
        justify-content: center;
      }
    }

    &__image {
      border: 1px solid var(--bs-primary-400);
      border-radius: 5px;
      background: white;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        width: 33%;
        max-width: 180px;
      }

      img {
        max-width: 180px;
        max-height: 150px;
        margin: auto;
        object-fit: contain;
      }
    }

    &__meta {
      .text-truncate {
        max-width: 300px;
      }
      @include media-breakpoint-up(md) {
        max-width: 77%;
      }
    }

    .button.button-link {
      height: auto;
      overflow: visible;
    }
  }

  a {
    cursor: pointer;

    @extend .button;
    @extend .button--primary;
    @extend .button--sm;

    margin-inline-start: rem(10px);
  }

  &__empty-state {
    &__label {
      display: flex;
      gap: 20px;
      flex-flow: column;
      align-items: center;
    }
  }
}

.truncate-full-width {
  display: flex;
  align-items: center;

  &__content {
    flex: 1;
    min-width: 0;

    > * {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.upload-list {
  display: flex;
  flex-flow: column;
  gap: 3px;
}

.upload-bar {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  background-color: var(--bs-space-gray-100);
  max-width: 100%;

  @include media-breakpoint-down(md) {
    max-width: calc(100vw - 80px);
  }

  &__figure {
    background-color: var(--bs-gray-200);

    img {
      display: block;
      width: 90px;
      min-width: 90px;
      height: 90px;
      object-fit: contain;
    }
  }

  &__label {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
  }

  &__actions {
    padding-right: 20px;
  }
}
