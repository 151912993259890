.pagination {
  display: inline-flex;

  &__item {
    &__link {
      @include size(rem(50px));

      color: theme-color(secondary);
      font-weight: bold;
      margin: 0 rem(2px);
      border-radius: rem(50px);
      transition: background 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;

      &.is--active {
        background: rgba(theme-color(secondary), 6%);
        color: theme-color(secondary);
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.2;
      }

      &:hover {
        background: rgba(theme-color(secondary), 6%);
        color: theme-color(secondary);
        text-decoration: none;
      }
    }
  }
}
