.panel {
  padding: 20px;
  display: flex;
  gap: 20px;

  @include media-breakpoint-down(md) {
    padding: 15px;
    flex-wrap: wrap;
    gap: 20px 10px;
  }

  &__main {
    flex: 1;
  }

  &__actions {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      width: 100%;
      justify-content: flex-end;
    }
  }
}
