.product-form {
  .header {
    position: relative;
    z-index: 2;
    padding-bottom: calc(var(--section-spacing) + 55px);
  }

  &__section {
    min-height: calc(100vh - 300px);
    position: relative;
    z-index: 15;

    &.set--success {
      .step-list {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }

  &__main {
    position: relative;
    z-index: 3;
    border: 1px solid var(--bs-space-gray-200);
    margin-top: -55px;
  }

  .step-list {
    gap: var(--bs-gutter-x);
    transition: opacity 0.3s ease-in-out;
  }

  &__figure {
    fstr-image img {
      border-radius: $border-radius-lg;
      object-fit: cover;
    }
  }

  &__step {
    max-width: 500px;
    margin: auto;
  }

  &__success {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .form.row {
    --bs-gutter-y: spacer(2);
  }
}
