.z-index-2 {
  z-index: 2;
}

.auth-layout {
  position: relative;
  overflow: hidden;
  //min-height: 100vh;
  padding-top: var(--topbar-height);

  @include media-breakpoint-up(md) {
    padding-top: calc(var(--topbar-height) + #{spacer(3)});
  }

  &__inner {
    position: relative;
    z-index: 10;
    margin-top: 8vh;

    @include media-breakpoint-down(md) {
      padding: $border-radius-xl spacer(3) spacer(3);
      background-color: var(--bs-light);
      border-radius: $border-radius-xl $border-radius-xl 0 0;
      margin-top: -50px;
    }
  }

  &__main {
    border-radius: 0 $border-radius-lg $border-radius-lg 0;
  }

  &__figure {
    @include media-breakpoint-down(md) {
      margin-left: -1.2rem;
      margin-right: -1.2rem;

      img {
        aspect-ratio: 16/10;
        object-fit: cover;
      }
    }
    @include media-breakpoint-up(md) {
      @include fxd(50%, 0, null, null);
      pointer-events: none;
      z-index: 1;
      width: 90vw;
      min-width: 110vh;
      overflow: hidden;
      border-radius: 50%;
      transform: translate(48%, -50%);

      &::before {
        content: '';
        padding-bottom: 100%;
        display: block;
      }

      img {
        @include abs(0, null, null, 0);
        @include size(100%);
        transform: translateX(-30%);
        object-fit: cover;
      }
    }
  }

  &__header__text {
    font-weight: 700;
    @include media-breakpoint-down(md) {
      font-size: rem(18);
    }
  }
}

.auth-form {
  position: relative;
  &::before {
    background-color: var(--bs-light);
    height: 100%;
    content: '';
    width: 100vw;
    position: absolute;
    top: 0;
    left: -100vw;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
