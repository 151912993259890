.stories-list {
  display: flex;
  gap: 1rem;
  flex-direction: column;

  app-story-list-card:not(:last-child) {
    border-bottom: 1px solid var(--bs-space-gray-300);
    padding-bottom: 1rem;
  }
}
