.accordion-step {
  &__title {
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      text-align: left;
      padding: 2ex 0 1ex;

      fstr-icon {
        svg {
          display: block;
          position: relative;
          transition: transform 0.3s ease-in-out;
          transform-origin: center center;
          fill: currentColor;

          .open--card & {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__main {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);

    .open--card & {
      max-height: 1000px;
      transition: max-height 0.6s ease-in-out;
    }
  }
}
