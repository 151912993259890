.mdc-switch {
  --mdc-switch-handle-width: 20px;
  --mdc-switch-track-height: 25px;
  --mdc-switch-track-shape: 50px;
  --mdc-switch-track-width: 40px;
  --mdc-switch-selected-track-color: var(--bs-success);
  --mdc-switch-unselected-hover-handle-color: var(--bs-secondary);
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: white;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: white;
  --mdc-switch-unselected-hover-handle-color: #fff;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #fff;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #{darken(theme-color(success), 0.1)};
  --mdc-switch-selected-handle-color: white;
  --mdc-switch-selected-hover-state-layer-color: #{darken(theme-color(success), 0.1)};
  --mdc-switch-selected-pressed-state-layer-color: #{darken(theme-color(success), 0.1)}; // 400
  --mdc-switch-selected-focus-handle-color: white; // 600
  --mdc-switch-selected-hover-handle-color: white;
  --mdc-switch-selected-pressed-handle-color: white;
  --mdc-switch-selected-focus-track-color: #{lighten(theme-color(success), 0.1)}; // 200
  --mdc-switch-selected-hover-track-color: #{darken(theme-color(success), 0.2)};
  --mdc-switch-selected-pressed-track-color: #{lighten(theme-color(success), 0.1)};
  --mdc-switch-selected-track-color: #{lighten(theme-color(success), 0.1)};
}

.mdc-switch__icons {
  display: none;
}

.mdc-switch:enabled .mdc-switch__shadow {
  box-shadow: none;
}

.mdc-switch--selected .mdc-switch__handle-track {
  transform: translateX(calc(100% - 1px));
}

.mdc-switch__handle-track {
  transform: translateX(1px);
}
