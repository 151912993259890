.step-list {
  display: flex;
  width: 100%;
  gap: calc(var(--bs-gutter-x) / 2);

  &__item {
    flex: 1;
  }
}

.stepper-button {
  width: 100%;

  &__title {
    margin-top: 20px;
    display: block;
  }
  &--line {
    width: 100%;
    height: rem(12);
    display: block;
    font-weight: bold;
    background: var(--bs-space-gray-200);
    color: var(--bs-space-gray-400);
    min-width: 10px;
    border-radius: 3px;
  }

  &.is--active,
  &.is--finished {
    &.stepper-button--line {
      background: var(--bs-secondary);
      color: var(--bs-secondary);
    }
  }
}
