.alert-banner {
  &__bar {
    &__inner {
      display: flex;
      align-items: center;
      color: $white;
      border-radius: 19px;
      padding: 16px;
      padding-right: 36px;
      position: relative;
      min-height: 74px;
      margin-bottom: 8px;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      padding-left: 8px;
      padding-right: 28px;
      fill: $white;
      margin-top: 5px;
      margin-bottom: auto;

      .icon {
        position: relative;

        &::before {
          @include abs(-12px, 0, 0, -12px);
          @include size(56px);
          content: '';
          background-color: $white;
          opacity: 0.2;
          border-radius: 50%;
        }
      }
    }

    &__text {
      @include media-breakpoint-down(md) {
        width: 100%;
        order: 3;
        margin-top: 3ex;
      }

      p {
        @extend .markdown-content;
        line-height: 1.5;
        margin-bottom: 0;
        color: $white;

        a,
        em,
        u {
          color: $white;
        }
      }
    }

    &__close {
      @include abs(12px, 14px, null, null);
      pointer-events: all;
      .button {
        background-color: alphaColor(var(--bs-light, 0.8));
      }
    }
  }
}

@each $name, $value in $alerts {
  .alert-type--#{$name} {
    background-color: $value;
    box-shadow: -20px 12px 74px rgba($value, 0.3);
  }
}
