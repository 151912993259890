.faq-question {
  width: 100%;
  border-bottom: 1px solid var(--bs-space-gray-200);

  .button {
    --button-size: auto;
    --button-padding-x: 0;

    position: relative;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: spacer(2);
    padding-right: spacer(4);
    padding-bottom: spacer(2);
    line-height: 1.4;

    fstr-icon {
      @include abs(13px, 0, null, null);
      transition: transform 0.3s ease-in-out;
    }

    &:hover {
      fstr-icon {
        transform: translateX(50%);
      }
    }
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: -#{spacer(3)};
  }
}

.results__empty {
  line-height: 1.5;
}
