.update-card {
  height: 100%;
  width: 100%;
  display: flex;

  @include media-breakpoint-up(md) {
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include media-breakpoint-down(md) {
      justify-content: center;
      height: auto;
    }
  }

  &__crop {
    @include media-breakpoint-down(md) {
      aspect-ratio: 1/1;
      height: max-content;
      flex: 0 0 33%;
      margin-right: 1rem;
    }

    img {
      border-radius: $border-radius;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        height: 100%;
      }

      @include media-breakpoint-up(md) {
        aspect-ratio: 16/9;
      }
    }
  }

  &__content__text {
    line-height: 2;
  }
}
