.waste-calendar-item {
  display: flex;
  gap: 10px;
  padding: 9px;

  @include media-breakpoint-up(md) {
    padding: 20px;
    gap: 15px;
  }

  &.is--active {
    background-color: #e9f8d9;
    @include media-breakpoint-down(md) {
      margin-inline: calc(var(--card-padding-x) * -1);
      padding-inline: var(--card-padding-x);
      border-radius: 0px;
    }
  }

  &__main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;

    @include media-breakpoint-down(md) {
      font-size: 0.9rem;
    }
  }
  .grip-opening-hours {
    white-space: nowrap;
  }
}
