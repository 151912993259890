.insight-graph {
  position: relative;

  &__empty-state {
    @include abs(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__disclaimer {
    color: var(--bs-space-gray-500);
  }
}

.legend {
  &__dotted,
  &__square {
    width: 1.2rem;
    display: inline-block;
  }

  &__square {
    height: 1.2rem;
  }

  &__dotted {
    border-top-width: 2px;
    border-top-style: dashed;
  }
}
