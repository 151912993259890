.color-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc(100% / 10);
    padding: 0 3px;
    text-align: center;
    margin-bottom: rem(40px);
  }
}

.styleguide-color {
  display: inline-block;
  width: 100%;
  height: 0;
  padding-bottom: calc(100% - 8px);
  border: 4px solid var(--bs-gray-100);
  border-radius: rem(200px);

  &--active {
    border-color: var(--bs-gray-900);
  }
}
