.alert {
  border-radius: $border-radius;
  border: 2px solid var(--bs-space-gray-200);
  display: flex;
  padding: spacer(3) spacer('2-5');
  align-items: center;
  line-height: 18px;
  font-weight: 600;
  color: var(--bs-space-gray-500);
  font-size: map-get($font-sizes, body-xxxs);
  justify-content: center;
  text-align: left;

  .form & {
    margin: spacer(2) 0 spacer(4);
  }

  fstr-icon {
    @include size(1.2rem);
    fill: currentColor;
  }

  &__content {
    flex: 1;
  }

  &__icon {
    @include size(40px);
    min-width: 40px;
    background-color: var(--bs-space-gray-200);
    border-radius: 50%;
    margin-right: spacer(3);
    display: flex;
    align-items: center;
    justify-content: center;
    fill: var(--bs-space-gray-500);
  }

  &--success {
    border-color: lightenColor(var(--bs-success), 0.4);
    background-color: lightenColor(var(--bs-success), 0.7);
    color: darkenColor(var(--bs-success), 0.1);

    .alert__icon {
      background-color: lightenColor(var(--bs-success), 0.6);
    }
  }

  &--danger {
    border-color: lightenColor(var(--bs-danger), 0.4);
    background-color: lightenColor(var(--bs-danger), 0.7);
    color: lightenColor(var(--bs-danger), 0.1);

    .alert__icon {
      background-color: lightenColor(var(--bs-danger), 0.4);
    }
  }

  &--warning {
    border-color: lightenColor(var(--bs-warning), 0.2);
    background-color: lightenColor(var(--bs-warning), 0.8);
    color: var(--bs-warning);

    .alert__icon {
      background-color: lightenColor(var(--bs-warning), 0.2);
      color: lightenColor(var(--bs-warning), 1);
    }
  }

  &--info {
    border-color: lightenColor(var(--bs-info), 0.4);
    background-color: lightenColor(var(--bs-info), 0.7);
    color: lightenColor(var(--bs-info), 0.1);

    .alert__icon {
      background-color: lightenColor(var(--bs-info), 0.4);
    }
  }

  pre {
    white-space: pre-wrap;
  }
}
