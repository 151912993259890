@each $name, $colors in $button-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .button.button--#{'' + $name} {
    --button-background: #{$bg-color};
    --button-background-darker: #{darkenColor($bg-color, 0.2)};
    --button-background-alpha-lighter: #{alpha($bg-color, 0.1)};
    --button-background-alpha: #{alpha($bg-color, 0.2)};
    --button-color: #{$font-color};
  }
}

.button--subtle.button-outline {
  background: var(--button-background-alpha-lighter);
  border: solid 1px;

  &:hover {
    background-color: var(--button-background-alpha);
  }
}

// custom font sizes
.button--sm.button {
  font-size: 1em;
}
.button--md.button {
  font-size: 1.1em;
}
