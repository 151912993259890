.app .fstr-cookie-notice {
  --cookie-notice-bg: white;

  border-radius: 20px !important;
  max-width: 600px;
  border-width: 2px;
  border-color: var(--bs-secondary);
  border-radius: $border-radius;
  padding: $spacer * 2;

  @include media-breakpoint-down(md) {
    bottom: calc(var(--bottom-bar-height) + #{spacer(4)});
  }

  .button-group {
    margin-top: 10px;
    gap: 1ex;

    .button--reject {
      display: none;
    }
  }

  .fstr-cookie-notice__title {
    @extend .h5;
    margin-bottom: 2ex;
    color: var(--bs-secondary);
  }

  .fstr-cookie-notice__toggle-row__content,
  .fstr-cookie-notice__content {
    font-size: 0.85em;
  }

  .fstr-cookie-notice__toggle-row__content.open {
    padding: 1ex 0;
  }

  .toggle-button {
    margin-left: 1ex;
    @include size(auto, auto);
    background-color: transparent;
    fill: theme-color(primary);
    color: theme-color(primary);
    padding: 0;

    .chevron {
      margin-left: 0;
    }

    &:focus-visible {
      outline: 2px dashed red !important;
    }
  }

  .toggle__switch {
    &::before,
    &::after {
      content: '';
    }

    &::after {
      height: calc(var(--cookie-toggle-size) - var(--cookie-toggle-spacing) * 2);
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
  }

  .button {
    @extend .button--sm;
    margin-bottom: 0 !important;
    text-align: center;
    justify-content: center;

    &:focus-visible {
      outline: 2px dashed red !important;
    }
  }

  .button--settings {
    border: 2px solid;
    color: var(--bs-secondary);
    background-color: transparent;
  }

  .button--primary {
    background-color: var(--bs-secondary);

    &:hover {
      background-color: var(--bs-secondary);
    }
  }

  .button--close {
    @include abs($spacer, 0, null, null);
    @include size(40px, 40px);
    font-size: 0px;
    // border-color: var(--bs-secondary);
    background-color: transparent;
    padding: 0;
    // border: 2px solid;
    color: var(--bs-secondary);

    &::after,
    &::before {
      @include abs(50%, null, null, 50%);
      content: '';
      width: 18px;
      height: 3px;
      border-radius: 2px;
      background-color: currentColor;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.embed-removed {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--bs-secondary-100);
  margin-bottom: 3ex;

  &__inner {
    @include abs(0, 0, 0, 0);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
    background-color: var(--bs-space-gray-300);
    text-align: center;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  .fstr-cookie-btn.button {
    font-size: 0.9em;
    margin-top: 3ex;
    background-color: var(--bs-primary);
  }
}
