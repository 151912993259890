app-waste-item {
  display: flex;
  width: 100%;
  container-type: inline-size;
}

.waste-item {
  --card-padding-x: 10px;
  --card-padding-y: 10px;
  --waste-item-figure-size: 75px;

  display: flex;
  gap: spacer(2);
  width: 100%;

  @container (min-width: 350px) {
    --card-padding-x: 1.2rem;
    --card-padding-y: 1.2rem;
    --waste-item-figure-size: 100px;
  }

  &__figure {
    width: var(--waste-item-figure-size);
    height: var(--waste-item-figure-size);
    min-width: var(--waste-item-figure-size);
    background-color: var(--bs-gray-200);
    border-radius: 5px;
    overflow: hidden;

    img {
      display: block;
      width: var(--waste-item-figure-size);
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    gap: spacer(2);
    width: 100%;

    @container (max-width: 400px) {
      font-size: rem(12px);
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;

      &__title {
        @container (max-width: 400px) {
          font-size: rem(15px);
        }
      }
    }

    &__label {
      align-self: flex-start;
      display: flex;
      align-items: center;
      gap: 10px;

      &,
      &:hover,
      &:focus {
        text-decoration: none;
        color: currentColor;
      }

      &__text {
        @container (max-width: 500px) {
          font-size: 0.8;
        }
        @container (max-width: 350px) {
          display: none;
        }
      }
    }
  }
}
