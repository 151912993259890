.insight-header {
  background: white;
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: var(--topbar-height);

  .no--link {
    &:hover {
      text-decoration: none;
    }
  }

  &__aside {
    position: relative;
  }

  &__main {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: center;
    }
    @include media-breakpoint-down(md) {
      text-align: center;
      padding-top: spacer(4);
    }
  }

  &__main__text {
    @include media-breakpoint-down(md) {
      max-width: 80vw;
      line-height: 1.4;
      margin: 0 auto spacer(4);
    }
  }

  &__figure {
    @include abs(50%, 0, null, null);

    pointer-events: none;
    z-index: -1;
    width: 40%;
    min-width: 600px;
    border-radius: 50%;
    overflow: hidden;

    transform: translate(30%, -50%);

    &::before {
      content: '';
      padding-bottom: 100%;
      display: block;
    }

    img {
      @include abs(0, null, null, 0);
      @include size(100%);
      transform: translateX(-3%);
      object-fit: cover;
    }
  }
}
