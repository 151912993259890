.header-cta-card {
  margin-top: auto;
  margin-bottom: calc(var(--section-spacing) * -1 + -20px);
  margin-left: auto;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
    .cta-card-title {
      font-size: 1rem;
    }
  }
}

.dashboard-deposit-list {
  display: flex;
  gap: 20px;
  width: 100%;

  @include media-breakpoint-down(md) {
    flex-flow: column;
    gap: 10px;
  }

  &__item {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 400px;
      margin-left: auto;
    }
  }
}

app-header {
  position: relative;
  z-index: 11;

  @include media-breakpoint-down(md) {
    z-index: 4;
  }
}

.dashboard {
  &__main {
    position: relative;
    z-index: 10;
  }
}

.dashboard__map {
  &.set--bigger-map {
    height: clamp(130px, 70vh, 400px);
  }
}

.waste-calendar-item-list {
  @include media-breakpoint-up(md) {
    border: 1px solid var(--bs-space-gray-200);
    border-radius: 10px;
  }

  @include media-breakpoint-up(md) {
    &--full-width {
      column-count: 2;

      .waste-calendar-item-list__item {
        width: 100%;

        &:after {
          margin-top: -1px;
        }

        &:first-child .waste-calendar-item {
          border-top-right-radius: 0;
        }
      }
    }
  }

  &__item {
    .waste-calendar-item {
      // border-radius: 10px; // px?

      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &:first-child .waste-calendar-item {
      @include media-breakpoint-up(md) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &.is--active {
        margin-bottom: -1px;
      }
    }
    &:last-child .waste-calendar-item {
      @include media-breakpoint-up(md) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &.is--active {
        margin-bottom: -1px;
      }
    }
    &:not(:last-child):not(.is--active)::after {
      content: '';
      width: 100%;
      height: 1px;
      background: var(--bs-space-gray-200);
      display: block;
      margin: auto;

      @include media-breakpoint-up(md) {
        width: 90%;
      }
    }
  }
}

.dashboard {
  .card {
    border: 1px solid var(--bs-space-gray-200);
  }
}

.top-task-grid {
  --bs-gutter-x: #{spacer(2)};
  row-gap: spacer(2);
}
.w-sm-100 {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
