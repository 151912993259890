.insight-dashboard {
  position: relative;

  &__header {
    @include media-breakpoint-down(md) {
      margin-bottom: spacer(3);
    }
    @include media-breakpoint-up(md) {
      @include abs(0, null, null, 0);
      width: 100%;
      transform: translateY(-50%);
    }
  }

  &__selector {
    background-color: var(--bs-space-gray-500);
    border-radius: 60px;
    color: var(--bs-light);
    display: flex;
    align-items: center;

    .button-icon {
      background-color: transparent;
    }
  }

  &__view-mode {
    .button {
      background-color: var(--bs-space-gray-500);

      @include media-breakpoint-down(md) {
        fstr-icon {
          transform: translateX(7px);
        }
      }
    }
  }
}
