.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-decoration-none {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
@each $name, $color in $space-grays {
  .#{'text-space-gray-' + $name} {
    color: var(--bs-space-gray-#{$name}) !important;
  }
}

.font-secondary {
  font-family: $font-family-sans-serif !important;
}

.font-primary {
  font-family: $font-family-serif !important;
}

.fw-semi {
  font-weight: 600 !important;
}

.block-link {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.text-current {
  color: currentColor !important;
}

.text-wrap-no {
  text-wrap: nowrap !important;
}
