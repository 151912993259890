.empty-state {
  padding: spacer(3);
  border: 1px solid;
  color: var(--bs-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  background: white;

  p {
    font-weight: 700;
    font-size: map-get($font-sizes, xs);
  }

  @include media-breakpoint-up(md) {
    padding: spacer(4);
    font-size: map-get($font-sizes, sm);
  }
}
