@mixin material-palette($primary-map, $accent-map, $warn-map) {
  $mat-pallette: map-remove($primary-map, contrast);
  $mat-accent-pallette: map-remove($accent-map, contrast);
  $mat-warn-pallette: map-remove($warn-map, contrast);

  @each $shade, $color in $mat-pallette {
    --mat-primary-#{$shade}: #{$color};
  }
  @each $shade, $color in $mat-accent-pallette {
    --mat-accent-#{$shade}: #{$color};
  }
  @each $shade, $color in $mat-warn-pallette {
    --mat-warn-#{$shade}: #{$color};
  }
}