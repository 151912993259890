.content-page-header {
  margin-bottom: rem(40px);
  &__intro {
    font-weight: 600;
    white-space: pre-wrap;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: rem(5px);

    //@include media-breakpoint-down(lg) {
    //  flex-flow: column;
    //}

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0 0.3em;
      line-height: 1.6;

      //@include media-breakpoint-down(lg) {
      //  margin-top: 0.6em;
      //}

      .tag:after {
        color: $white;
      }
      //.tag:first-child {
      //  @include media-breakpoint-up(lg) {
      //    &::before {
      //      content: ' |';
      //      margin-right: rem(5px);
      //      color: var(--bs-light);
      //    }
      //  }
      //}

      //.tag:not(:last-child):after {
      //  content: ',';
      //}

      a {
        display: inline-block;
      }
    }
  }

  &__figure {
    position: relative;
    background-color: var(--bs-space-gray-200);
    img {
      width: 100%;
      aspect-ratio: 21/9;
      max-height: 70vmin;
      object-fit: cover;
      border-radius: 0.5rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      height: 70%;
      background: theme-color(primary);
    }

    &.is--low {
      &:before {
        height: 40%;
      }
    }
  }
}
