.location-form__button {
  padding: 2ex;
  border: 1px solid var(--bs-space-gray-300);
  display: flex;
  border-radius: 1ex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(md) {
    width: 48%;
    min-width: 238px;
  }

  &:hover {
    background-color: var(--bs-space-gray-100);
  }

  &.set--active {
    background-color: var(--bs-space-gray-100);
    border: 1px solid var(--bs-secondary);

    strong {
      color: var(--bs-secondary);
      .icon {
        fill: currentColor;
      }
    }
  }

  strong {
    display: flex;
    align-items: center;
    gap: 0.5ex;
  }
}

.location-form {
  &__header {
  }
}
