:root {
  --chip-size: #{$button-size};
}

.chip,
.button .chip {
  --chip-color: var(--bs-primary);
  --chip-bg: var(--bs-light);
  --chip-icon-size: calc(var(--chip-size, 1em) / 2.5);

  @include size(var(--chip-size));
  min-width: var(--chip-size);
  background-color: var(--chip-bg);
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  padding: 9px 0;
  fill: var(--chip-color);
  transition: background 0.35s ease;
  align-items: center;

  @include media-breakpoint-down(md) {
    --chip-size: 36px;
  }

  .icon * {
    fill: var(--chip-color);
  }

  span + fstr-icon,
  span + .css-icon {
    margin-inline-start: rem(8px);
  }

  .icon-chip + span,
  fstr-icon + span,
  .css-icon + span {
    margin-inline-start: rem(8px);
  }

  fstr-icon {
    @include size(var(--chip-icon-size));
    min-width: var(--chip-icon-size);
    min-height: var(--chip-icon-size);
  }

  fstr-icon .icon {
    fill: inherit;
  }

  &--label {
    width: auto;
    padding: 9px;
    font-weight: 700;
    border-radius: 50px;
    display: inline-flex;
    line-height: 1;
  }
}

.chip-outline {
  background-color: var(--chip-bg-lighter);
  color: var(--chip-bg);
  fill: var(--chip-bg);
  border: 1px solid var(--chip-bg);
}
