.trash-icon {
  --trash-icon-size: 3em;
  width: var(--trash-icon-size);
  min-width: var(--trash-icon-size);
  height: var(--trash-icon-size);
  border-radius: 20%;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.4em;
  position: relative;

  fstr-icon {
    width: 100%;
    height: 100%;

    .icon {
      fill: currentColor;
    }
  }

  &__marker {
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}
