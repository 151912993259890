.focus-only {
  max-height: 0;
  @extend .sr-only;

  &:focus,
  &:focus-within {
    max-height: 100px;
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    margin: inherit;
    overflow: visible;
    clip: unset;
    white-space: initial;
    border: 0;
  }
}
