.mat-form-field--checkbox {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: transparent;
    padding: 0;
  }
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding: 0;
    min-height: 0;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border: none;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }
}
