
@import './scss/video';
@import './scss/controls';

.content-slide {
  white-space: initial;
  cursor: grab;

  img {
    position: initial;
    width: 100%;
    height: auto;
  }
}

.content-slider-content {
  transition: all .3s;
  position: relative;
  z-index: 2;
  padding: 20px 20px 12px;
  margin: -10px 10px 0;

  ul {
    line-height: 1.5;
  }

  u, em, strong {
    color: inherit;
  }

  table {
    color: gray-color(700);
  }

  blockquote {
    padding: 0 20px 0 10px;

    &::before {
      opacity: .2;
      top: -6px;
      left: -16px;
    }

    &, * {
      color: inherit;
      font-size: 1.2rem;
      line-height: 1.7;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 40px 40px 32px;
    margin: -20px 30px 10px;
  }

  @include media-breakpoint-up(md) {
    margin: -30px 50px 20px;
  }

  &.slider-is--updating {
    transform: translateY(20px);
    opacity: 0;
  }
  &.slider-is--visible {
    transform: translateY(0);
    opacity: 1;
  }

  .button {
    margin-bottom: 8px;
  }
}

.content-slider {
  // TODO duplicate of markdowncontent
  $grid-column-outset: 100px; // is used to expand x pixels out of the container
  $markdown-font-sizes: ( 
    h1: 38px,
    h2: 28px,
    h3: 22px,
    h4: 18px,
  );

  display: block;
  width: 100%;
  margin-bottom: map-get($spacers, 4);

  &:empty {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    display: inline-block;
    position: relative;
    overflow: visible;
    max-width: none;
    margin: rem(20px) -#{$grid-column-outset / 2} 0;
    width: calc(100% + #{$grid-column-outset});
  }

  @include media-breakpoint-up(lg) { 
    margin: rem(20px) -#{$grid-column-outset} 0;
    width: calc(100% + #{$grid-column-outset * 2});
  }

  &.has--intro {
    margin-top: 0;
  }

  &:not(.has--intro) {
    margin-top: 40px;

    &:after {
      top: -30px;
    }
  }

  @extend .aspect-ratio;

  &__slider {
    @extend .aspect-ratio-element;

    z-index: 2;
  }
  

  &:after {
    content: '';
    background: space-gray-color(100);
    position: absolute;
    left: -100vw;
    right: -100vw;
    top: 0;
    bottom: 30%;
    z-index: 0;
  }
}

.content-slider__intro {
  position: relative;
  margin-top: 40px;
  padding: 20px 0;
  
  &:after {
    content: "";
    background: #f7f9fa;
    position: absolute;
    left: -100vw;
    right: -100vw;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  * {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
  }
}