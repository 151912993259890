.topbar__bar {
  background-color: #ffffff8a;
  padding-top: 10px;
  padding-bottom: 10px;

  .set--ios & {
    // backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.94);

    @include media-breakpoint-down(lg) {
      padding-top: env(safe-area-inset-top); //ios notch fix
    }
  }

  .nav-list-bar {
    transition: opacity 0.1s;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__logo {
    .logo-fill-theme {
      fill: var(--logo-color, var(--bs-secondary));
    }
  }

  .hamburger-button {
    border-radius: 0;
  }

  .is--open & {
    background-color: transparent;

    @include media-breakpoint-down(md) {
      --logo-color: white;
    }

    .nav-list-bar {
      opacity: 0;
      pointer-events: none;
    }
  }

  .topbar-actions {
    .button.is--active {
      color: var(--button-background);
      background: var(--button-background-alpha);
    }
  }
}
