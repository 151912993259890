// Functions
@import 'functions/all';

// Config
@import 'config';

// Mixins
@import 'mixins/all';

// Vendor
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import './material/all';

// Applying config to base tags etc.
@import 'base/all';
@import 'themes/all';

@import 'utils/all';
@import 'components/all';

// Angular components
@import 'src/app/app.module.scss';
