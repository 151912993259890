.channel-item {
  text-align: center;

  &__figure {
    @include size(rem(150));
    border-radius: 50%;
    border: 2px solid rgba($gray-300, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1.25rem;
    background-color: white;

    @include media-breakpoint-down(sm) {
      @include size(rem(120));

      fstr-icon .icon {
        @include size(45px);
      }
    }
  }

  &__title {
    line-height: 1;
  }

  &__link {
    color: black;

    display: inline-flex;
    align-items: center;
    gap: 0.4em;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: black;
      text-decoration: underline;
    }
  }
}
