.theme-contrast {
  $selected-theme-colors: map-remove($theme-colors, "light");

  @each $name, $color in $selected-theme-colors {
    @include defineColorHSL(--bs-#{$name}, hue($color), saturation($color), (lightness($color) - 20%));
  }

   @each $name, $color in $colors {
    @include defineColorHSL(--bs-#{$name}, hue($color), saturation($color), (lightness($color) - 20%));
  }
}