.guide-alert {
  --alert-padding: 20px;
  padding: 20px;
  position: fixed;
  left: 15px;
  right: 15px;
  top: 4.2em;
  box-shadow: rgb(0 0 0 / 30%) 0px 10px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
  }

  &::before {
    --caret-size: 9px;
    content: '';
    @include abs(calc(var(--caret-size) * -1), 55px, null, null);
    @include size(0);
    border-left: calc(var(--caret-size) / 1.2) solid transparent;
    border-right: calc(var(--caret-size) / 1.2) solid transparent;
    border-bottom: var(--caret-size) solid var(--bs-secondary);
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    left: auto;
    right: 0;
  }
}
