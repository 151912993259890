.menu {
  display: flex;
  flex-flow: column;

  &.depth--0 {
    gap: 10px;

    > .menu__item {
      background: white;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

.menu__item {
  position: relative;
  margin-top: 1px;
}

.menu__item .menu__item:not(:last-child)::after,
.menu .menu::before {
  content: '';
  width: calc(100% - 10px);
  height: 1px;
  background: var(--bs-gray-200);
  display: block;
  position: absolute;
}

.button.button-link.menu-link {
  width: 100%;
  text-align: start;
  justify-content: flex-start;
  padding: 20px 18px;
  height: auto;

  @media (hover: hover) {
    &:hover {
      background-color: #f9f9fa;
      color: var(--bs-secondary);
    }
  }
}
