.notifications-form {
  // --mdc-filled-text-field-container-color: #{var(--bs-space-gray-200)};
  // --mdc-filled-text-field-active-indicator-color: #{var(--bs-secondary)};
  // --mdc-filled-text-field-label-text-color: #{var(--bs-secondary)};

  .icon {
    fill: currentColor;
  }

  &__footer {
    margin: var(--card-padding-x) calc(var(--card-padding-x) * -1) calc(var(--card-padding-y) * -1) calc(var(--card-padding-x) * -1);
    padding: 20px var(--card-padding-x);
    border-radius: 0 0 0.6rem 0.6rem;
    display: flex;

    &.is--success {
      background: var(--bs-success-50);
    }
    &.is--danger {
      background: var(--bs-danger-50);
    }
  }
}
