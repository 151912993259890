.form .row {
  --bs-gutter-y: 5px;
}

.form__fieldset.is--hidden {
  display: none;
}

.form__fieldset__header {
  margin-top: 20px;

  &__title {
    font-weight: bold;
    display: inline-block;
    width: 100%;
    margin-bottom: 0.6em;
  }
  &__toggle {
    display: none;
  }
}

.form__footer {
  text-align: center;
}

.form__fieldset:first-child .form__fieldset__header {
  margin-top: 0;
}
