.slider-video {
  position: relative;

  &__overlay {
    @include abs(0);
    background: rgba(theme-color(secondary), .6);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 2;

    fstr-icon {
      @include size(60px);
      font-size: 20px;
      padding-left: 3px;
      fill: theme-color(primary);
      background: white;
      border-radius: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: background .2s;

      &:hover {
        background-color: space-gray-color(200);
        fill: darken(theme-color(primary), 10%)
      }
    }

    &:focus-visible {
      fstr-icon {
        outline: 2px solid red;
      }
    }
  }

  iframe {
    min-height: 0;
  }
}

.video-placeholder {
  margin: 0;
  width: auto;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  display: block;

  &.video-placeholder__no-cookies {
    display: flex;
    justify-content: center;
    align-items: center;
    background: theme-color(secondary);
    color: white;

    > strong {
      @include abs(0);
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
  }
}


.video-skip {
  &:focus {
    background: white;
    z-index: 2;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &--after:focus {
    top: auto;
    bottom: 10px;
  }
}

.fstr-youtube__container {
  // padding-bottom: 58.08%!important;
}