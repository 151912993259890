// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Helpers
@import '../mixins/all';
@import '../functions/all';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Project specific settings
$mat-primary-map: makeColorPalette(theme-color(primary));
$mat-accent-map: makeColorPalette(theme-color(secondary));
$mat-warn-map: makeColorPalette(theme-color(danger));

$mat-typography: mat.define-legacy-typography-config(
  $font-family: $font-family-sans-serif,
);

/*
  Material implementation
*/

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($mat-primary-map),
      accent: mat.define-palette($mat-accent-map),
      warn: mat.define-palette($mat-warn-map),
    ),
    typography: $mat-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($custom-theme);
