@keyframes pulseScale {
  from {
    opacity: 0.4;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

.chip.chip--pulsing {
  --animation-duration: 2s;
  --animation-delay: 0.1s;
  position: relative;

  &::after,
  &::before {
    content: '';
    width: var(--chip-size);
    height: var(--chip-size);
    background: var(--bs-primary);
    border-radius: 50%;
    display: block;
    position: absolute;
    z-index: 0;
    opacity: 0.2;
    animation: pulseScale var(--animation-duration) forwards ease-out infinite;
    animation-delay: var(--animation-delay);
    opacity: 0;
  }

  &::after {
    animation-delay: calc(var(--animation-duration) / 2 + var(--animation-delay));
  }

  fstr-icon {
    position: relative;
    z-index: 2;
  }
}
