.alerts-container {
  position: relative;
  pointer-events: none;
  width: 100%;
  transform: translate3d(-50%, var(--topbar-height), 0); // topbarheight

  &.set--absolute {
    @include abs(0, null, null, 50%);

    transform: translate3d(-50%, var(--topbar-height), 0);
    transition: transform 0.2s ease-in-out;
    z-index: 1000;
  }

  &.set--fixed {
    position: fixed;
    transform: translate3d(-50%, 0, 0);
    transition: none;
    animation: alerts-slide-in 0.2s ease-in-out both;
  }

  .alert-banner {
    pointer-events: all;
  }
}

@keyframes alerts-slide-in {
  0% {
    transform: translate3d(-50%, -100%, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(-50%, var(--topbar-height), 0);
    opacity: 1;
  }
}
