.pass-number-form-cta {
  display: flex;
  justify-content: space-between;
  //align-items: center;
  gap: 1rem;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
  .pass-number-form {
    width: 100%;
  }
  .form {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    //gap: 12px;

    .mat-mdc-form-field-error-wrapper {
      background: var(--bs-danger-50);
      padding-top: 5px;
      padding-bottom: 5px;
    }
    &__footer {
      margin-top: 10px;

      @include media-breakpoint-up(sm) {
        width: auto !important;
      }
    }
  }
}
.icon--rotating {
  fill: var(--bs-primary);
}

@include media-breakpoint-up(lg) {
  .pass-number-form-cta {
    .form__footer {
      width: 200px !important;
      align-items: flex-start;
      display: flex;
      order: 2;
      margin-top: 0;
    }
    .pass-number-form-field {
      width: calc(100% - 200px);

      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 3;
      }
    }
  }
}
