.footer {
  background: var(--bs-space-gray-200);
  text-align: center;
}

.footer__nav-list {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px 30px;

  a {
    text-decoration: none;
  }
}
