// Bootstrap
$colors: (
  blue: #244b8a,
  indigo: #6610f2,
  purple: #6f42c1,
  pink: #e83e8c,
  red: #ea3b3b,
  orange: #e6914e,
  yellow: #ffc107,
  green: #8ac249,
  teal: #20c997,
  cyan: #17a2b8,
);

$theme-colors: (
  primary-700: #429321,
  primary: color(green),
  // Primary tints from 100-900:
  primary-200: #aacf40,
  primary-100: #b4ec51,
  secondary: color(blue),
  // Secondary tints from 100-900:
  secondary-400: #4d6c9e,
  secondary-300: #879dbe,
  secondary-200: #8bb0eb,
  secondary-100: #eef2f5,
  success: color(green),
  success-50: #e1f2c4,
  info: #639ce0,
  warning: color(orange),
  danger: color(red),
  danger-50: #e9c9c9,
  light: #fff,
  light-alpha: rgba(#fff, 0.2),
  dark: #1c1c1c,
  muted: #7f9fbd,
  muted-100: rgba(#fff, 0.3),
);

$grays: (
  // Overwrite these grays from 100-900,
  // or add your own gray keys.
  100: #fcfcfc,
  200: #f2f4f8,
  300: #eee,
  500: #ededed,
  600: #ccd4d8,
  700: #505050,
  800: #333,
  900: #1c1c1c
);

$space-grays: (
  100: #f7f9fa,
  200: #eef2f5,
  250: #f5f7f9,
  300: #d3dbe8,
  400: #a8afb5,
  500: #7a8f99,
  550: #4a6c7b,
  600: #566870,
);

$gradients: (
  primary: (
    linear-gradient(273.68deg, theme-color(primary-100) -16.05%, theme-color(primary-700) 131.45%),
    theme-color(primary),
  ),
  primary-transparent: (
    linear-gradient(263.76deg, rgba(theme-color(primary-100), 0.55) -16.05%, rgba(theme-color(primary-700), 0.55) 131.45%),
    theme-color(primary),
  ),
  secondary: (
    linear-gradient(270deg, #254b8a 0%, #3b5b8f 100%),
    theme-color(secondary),
  ),
);

$alerts: (
  info: theme-color(info),
  warning: theme-color(warning),
  danger: theme-color(danger),
);

$trash-types-old: (
  REST: #64656a,
  RST: #64656a,
  RESTAFVAL: #64656a,
  // Restafval
  GFT: #418740,
  GFT-EM: #418740,
  GFTE: #418740,
  // GFT
  PAPIER: #2a70b8,
  PAP: #2a70b8,
  LOSPAP: #2a70b8,
  LOS_PAPIER: #2a70b8,
  PD: #2a70b8,
  // Papier
  PLASTIC: #e96c29,
  PMD: #e96c29,
  PMD_IN_ZAKKEN: #e96c29,
  PMDZAK: #e96c29,
  MD: #e96c29,
  // Plastic
  DHM: rgb(124, 106, 97),
  // Droge herbruikbare materialen
  BTG: rgb(154, 81, 187),
  // Blik, textiel en glas
  PPBTG: rgb(52, 109, 195),
  // Papier, plastic, blik, textiel en glas
  GROF: rgb(232, 76, 94),
  // Gratis grof tuinafval brengdagen
  PLASTICPLUS: #e96c29,
  // Plastic, Blik, Sap- en Zuivelpakken
  PTG: rgb(79, 147, 111),
  // Papier Textiel Glas
  KRINGLOOP: #7cbf6e,
  KCA: #e64e61,
  GLAS: #ffc729,
  TAKKEN: #529270,
);

$trash-types: (
  klein-chemisch-afval: #e54f61,
  asbest: #dc7070,
  banden: #bc5b8d,
  bouw-en-sloop: #e34f4f,
  bruin-en-witgoed: #aaabab,
  dakleer: #e34f4f,
  electronische-apparaten: #aaaaaa,
  frituurvet-olie: #a69d34,
  geimpregneerd-hout: #95785f,
  gft: #587f40,
  gfte: #587f40,
  gips: #e34f4f,
  glas: #f7b500,
  grip: #054a8a,
  grof-huishoudelijk-afval: #7c6a62,
  grof-tuinafval: #529270,
  grond: #917951,
  hard-kunststof: #e5904d,
  hout: #957860,
  kadavers: #986b99,
  kringloop: #7dbe6e,
  luiers: #998cc2,
  matrassen: #3d648c,
  metalen: #9a9b9c,
  // milieubrengstation-old: #2a4575,
  milieubrengstation: #b8947f,
  papier-karton: #007cb0,
  los-papier: #007cb0,
  piepschuim: #e34f4f,
  pmd: #e86c29,
  pmd-in-zakken: #e86c29,
  puin: #e34f4f,
  restafval: #646469,
  tapijt: #baa783,
  textiel: #8f58a3,
  vlak-glas: #5cbee2,
  zonnepanelen: #ebe745,
  kantoor: #a8a8a8,
  medisch-afval: #7a7b82,
);

$ladder-lansink: (
  A: #59a73a,
  B: #91ca48,
  C: #bac300,
  D: #efb343,
  E: #e8783e,
  F: #d93100,
);

// Other
//? Put own extra variables here
