.width--unset {
  width: unset;
}
.mat-mdc-form-field {
  --mdc-shape-small: 40px;
}

.wastecalendar__section.no--data {
  pointer-events: none;
  opacity: 0.7;
  filter: blur(5px);
}

.waste-calendar__alert {
  position: absolute;
  top: 10ex;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.waste-calendar__main {
  position: relative;
  z-index: 10;
}

.waste-calendar {
  @include media-breakpoint-down(md) {
    .card {
      border: 1px solid var(--bs-space-gray-200);
    }
  }
}

.header-cta-card.type--tip {
  line-height: 1.4;
  align-items: center;
  border: 1px solid var(--bs-space-gray-200);

  .button-link {
    font-size: 0.9em;
  }

  .chip {
    --chip-icon-size: 2rem;
    --chip-bg: #{alpha(var(--bs-primary), 0.2)};
  }
}
