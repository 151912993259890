.calendar__container {
  table-layout: fixed;
}
.calendar-cell {
  height: 90px;
  background-color: var(--bs-space-gray-100);
  border: 4px solid var(--bs-light);
  vertical-align: middle;

  @include media-breakpoint-down(sm) {
    height: 50px;
    border-width: 2px;
  }

  .trash-icon__marker {
    right: 5px;
    transform: none;

    @include media-breakpoint-down(sm) {
      right: 1px;
      top: 1px;

      --chip-size: 1.2em;
      padding: 0;
    }
  }

  &__label {
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 1;
    color: var(--bs-secondary);
    @include media-breakpoint-down(md) {
      font-size: 10px;
    }
  }

  &__trash-item {
    justify-content: center;
    display: flex;
    align-items: center;
    max-height: 90px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      // aspect-ratio: 1;
    }

    fstr-icon {
      @include size(3em);
      fill: var(--bs-light);

      @include media-breakpoint-down(md) {
        @include size(2em);
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
}
.has--day-items {
  .calendar-cell {
    &__trash-item {
      display: flex;
      justify-content: center;

      &:first-of-type {
        justify-content: flex-start;
        align-items: flex-start;

        fstr-icon {
          @include media-breakpoint-down(sm) {
            left: 0;
            top: 0;
          }
        }
      }
      &:not(:first-of-type) {
        justify-content: flex-end;
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        height: 100%;
        width: 100%;
        @include abs(auto, 0, 0, auto);

        display: flex;
        align-items: flex-end;

        fstr-icon {
          right: 0px;
          bottom: 0px;
          top: auto;
        }
      }

      fstr-icon {
        @include size(2.4em);
        @include media-breakpoint-down(md) {
          @include size(1.5em);
        }
      }
    }
  }
  &.is--today {
    box-shadow: inset 0 0 0 5px var(--bs-primary);

    @include media-breakpoint-down(sm) {
      border-width: 3px;
    }

    .calendar-cell__label {
      background: var(--bs-primary);
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      line-height: 1;
      bottom: 3px;
      left: 2px;
    }
  }
}
.is--disabled-day {
  .calendar-cell__trash-item {
    fstr-icon {
      opacity: 0.7;
    }
    .calendar-cell__label {
      color: var(--bs-muted-100) !important;
    }
  }

  background-color: var(--bs-space-gray-200);
  opacity: 0.3;
}
