.deposit-compare-card {
  padding-bottom: 0;
  overflow: hidden;

  &__title {
    text-wrap: balance;
    @include media-breakpoint-up(md) {
      width: 65%;
    }
  }

  &__figure {
    width: 80px;

    img {
      width: 100%;
    }
  }

  &__content {
    flex: 1;
    text-wrap: pretty;

    @include media-breakpoint-up(md) {
      padding-right: 3rem;
    }

    b {
      font-weight: 700;
    }

    strong:first-of-type {
      color: var(--bs-success);
    }

    &.set--negative {
      strong:first-of-type {
        color: var(--bs-warning);
      }
    }
  }

  &__footer {
    padding: var(--card-padding-y) var(--card-padding-x);
    margin-left: calc(-1 * var(--card-padding-x));
    margin-right: calc(-1 * var(--card-padding-x));
    font-weight: 600;

    &__text {
      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
  }
}
