@each $name, $colors in $button-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .chip.chip--#{'' + $name} {
    --chip-bg: #{$bg-color};
    --chip-bg-lighter: #{lightenColor($bg-color, 0.6)};
    --chip-color: #{$font-color};

    &.chip-outline {
      --chip-color: #{$bg-color};
    }
  }
}
