:root {
  --topbar-height: 72px;

  @include media-breakpoint-down(lg) {
    --topbar-height: 64px;
  }
}

.main-nav--active {
  overflow: hidden;
  height: 100vh;
}

.pt-topbar {
  padding-top: var(--topbar-height);
}

.mt-topbar {
  margin-top: var(--topbar-height);
}

.topbar {
  @include fxd(0, null, null, 0);
  width: 100%;
  z-index: 1000;
  height: var(--topbar-height);

  &__bar__logo {
    max-height: 50px;
    max-width: 187px;
    display: inline-flex;

    @include media-breakpoint-down(lg) {
      max-width: 110px;
    }

    img,
    svg {
      width: 100%;
      height: auto;
    }
  }

  &__bar {
    position: relative;
    z-index: 2; // above overlay
    transition: background-color 0.2s ease;

    &__inner {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    &__nav-lists {
      display: flex;
      gap: spacer(3);
    }
  }

  &.is--fixed {
    .topbar__bar {
      background-color: var(--bs-light) !important;
    }
  }

  &__overlay {
    @include fxd(0);
    pointer-events: none;
    //opacity: 0;

    &.is--open {
      pointer-events: auto;
      //opacity: 1;
    }

    &__footer-menu {
      display: flex;
      align-items: center;
      column-gap: 1.3rem;
      border-top: solid 0.125rem var(--bs-space-gray-400);
    }
  }
}

.topbar-actions {
  display: flex;
  gap: 4px;
  align-items: center;
}

@import './hamburger-icon';
@import './nav-lists';
@import './overlay-content';
@import './bar-content';
