.complete-notification-card {
  &__list {
    .chip {
      --chip-color: var(--bs-secondary);
      --chip-bg-lighter: var(--bs-space-gray-200);
      --chip-bg: var(--chip-bg-lighter);
    }

    &__item {
      position: relative;
      font-weight: 600;
      // border-left: 1px solid var(--bs-space-gray-200);

      @include media-breakpoint-down(lg) {
        display: flex;
        gap: 1rem;
        text-align: left;
        margin-bottom: 1ex;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 1rem;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 70%;
          background-color: var(--bs-space-gray-200);
          transform: translate(-50%, -50%);
        }

        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
