.topbar__overlay {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &__backdrop {
    @include abs(0);
    background-color: rgba(white, 0.6);
    opacity: 0;
    transition: opacity 0.2s;
  }

  &__nav {
    transform: translate3d(30%, 0, 0);
    opacity: 0;
    transition:
      transform 0.3s,
      opacity 0.2s;

    padding-top: var(--topbar-height);
    background-color: var(--bs-secondary);
    width: 100%;
    max-width: 800px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;

    @include media-breakpoint-down(md) {
      transition:
        transform 0.2s,
        opacity 0.1s;
      transform: translate3d(15%, 0, 0);
    }

    &__inner {
      overflow: auto;
      width: 100%;
      @include scrollbar;
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md) {
        padding: 0 30px;
      }
    }
  }

  &.is--open {
    .topbar__overlay__backdrop {
      opacity: 1;
    }
    .topbar__overlay__nav {
      opacity: 1;
      transform: translate3d(0%, 0, 0);
    }
  }
}
