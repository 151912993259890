.app {
  --mdc-filled-text-field-container-color: #{var(--bs-space-gray-100)};
  --mdc-filled-text-field-active-indicator-color: #{var(--bs-space-gray-300)};
  --mdc-filled-text-field-label-text-color: #{var(--bs-secondary)};
  --mdc-filled-text-field-container-shape: 0px;

  .mdc-text-field--filled {
    --mdc-filled-text-field-container-shape: 0px;
  }

  .mat-mdc-form-field:hover {
    --mdc-filled-text-field-active-indicator-color: #{var(--bs-secondary)};
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--mdc-background-color);
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-weight: 700;
  color: var(--bs-secondary);
}

.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field-infix {
  width: auto;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-bottom-border-color, gray);
}

.app .mat-mdc-form-field-hint-wrapper {
  position: relative;
}

@keyframes underline-loading {
  from {
    transform: scaleX(0) translate(0, 0);
  }
  to {
    transform: scaleX(1) translate(100%, 0);
  }
}

.mat-mdc-form-field {
  &.is--loading {
    .mdc-line-ripple::after {
      transform: scaleX(0.1);
      transform-origin: left;
      opacity: 1;
      animation: underline-loading 2s infinite ease-in-out;
      background: var(--bs-secondary);
    }

    .mdc-line-ripple::before {
      overflow: hidden;
      height: 2px;
      background: alpha(var(--bs-space-gray-300), 0.2);
    }
  }
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  font-weight: 600;
}

.mat-mdc-form-field-error a {
  color: var(--mat-form-field-error-text-color);
  font-weight: 600;

  @media (hover: hover) {
    &:hover {
      color: color-mix(in srgb, var(--mat-form-field-error-text-color) 90%, black);
    }
  }
}
