.time-list {
  font-size: rem(14px);

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 5px;

    &:nth-child(even) {
      background: rgba($gray-300, 0.3);
    }
  }

  &--md {
    .time-list__item {
      padding: 10px;
    }

    .time-list__item:last-child {
      padding-bottom: 20px;
    }
  }
}
