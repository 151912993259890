/**
 * Section class for inner padding, outer margin or negative margin.
 * The negative option is used to pull a section over another to create overlapping content.
 */

[class^='section-'] {
  position: relative;
}

[class^='section-negative-'] {
  z-index: 1;
}

@mixin section-query($max-width) {
  @media (min-width: $max-width) {
    @content;
  }
}

@each $type, $prop in (inset: padding, outset: margin) {
  $class: 'section-#{$type}';
  $max: $section-offset;
  $min: 20px;
  //$max-width: $max * 10px;
  //$fluid: $max * 0.1vw;

  .#{$class}-y {
    --section-spacing: #{$max};

    #{$prop}-top: var(--section-spacing);
    #{$prop}-bottom: var(--section-spacing);

    @include media-breakpoint-down(md) {
      --section-spacing: #{$min};
    }
  }

  .#{$class}-t {
    --section-spacing: #{$max};
    #{$prop}-top: var(--section-spacing);

    @include media-breakpoint-down(md) {
      --section-spacing: #{$min};
    }
  }

  .#{$class}-b {
    --section-spacing: #{$max};
    #{$prop}-bottom: var(--section-spacing);

    @include media-breakpoint-down(md) {
      --section-spacing: #{$min};
    }
  }
}
