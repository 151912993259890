@import '@glidejs/glide/src/assets/sass/glide.core';

.glide__bullets {
  display: flex;
}

.glide__bullet {
  @include size(11px, 11px);
  border-radius: 50%;
  background-color: gray-color(200);
  margin-left: 3px;

  &--active {
    background-color: theme-color(secondary);
  }

  &:first-of-type {
    margin-left: 0;
  }
}
