.waste-guide__search-form {
  margin-bottom: calc(var(--section-spacing) * -1 - 28px);
  max-width: 600px;

  @media screen and (max-width: 350px) {
    margin-bottom: calc(var(--section-spacing) * -1 - 18px);
  }
}

.waste-guide__main {
  margin-top: 30px;
}

.search-field.mat-mdc-form-field {
  --mat-field-button-spacing: 7px;
  --mat-field-height: 56px;
  --mdc-filled-text-field-container-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 100px;

  .mdc-text-field--filled {
    border-radius: 100px;
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    .button {
      height: calc(var(--mat-field-height) - (var(--mat-field-button-spacing) * 2));
    }
  }

  .mat-mdc-form-field-icon-suffix,
  [dir='rtl'] .mat-mdc-form-field-icon-prefix {
    padding: 0px var(--mat-field-button-spacing) 0px var(--mat-field-button-spacing);
  }

  .mat-mdc-form-field-icon-prefix fstr-icon {
    margin: 0 10px 0 15px;
  }

  .mat-mdc-form-field-infix {
    min-height: var(--mat-field-height);
  }

  .mdc-line-ripple {
    display: none;
  }
}

.waste-item-list__item {
  display: flex;
}
