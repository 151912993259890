.confirm-form {
  .accordion {
    padding-top: spacer(3);
  }
}

.confirm-form__data {
  .mat-form-field--checkbox {
    transform: translateX(-9px);
  }
}
