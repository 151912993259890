// body {
//   --bottom-bar-height: 70px;

//   .set--ios,
//   .set--ios.set--standalone {
//     --bottom-bar-height: 100px;
//   }

//   @include media-breakpoint-down(md) {
//     .app {
//       padding-bottom: var(--bottom-bar-height);
//     }
//   }
// }

body {
  --bottom-bar-height: 70px;

  @include media-breakpoint-down(md) {
    padding-bottom: calc(env(safe-area-inset-bottom) + var(--bottom-bar-height));
  }
}

.bottom-bar {
  --bottom-bar-icon-size: 24px;
  @include fxd(null, null, 0, 0);

  z-index: 999;
  background-color: var(--bs-light);
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding-bottom: 0.6rem;
  // padding-bottom: env(safe-area-inset-bottom);
  height: calc(var(--bottom-bar-height) + env(safe-area-inset-bottom));

  &__list {
    justify-content: space-around;

    &__item {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;

      fstr-icon {
        @include size(var(--bottom-bar-icon-size));
      }

      &:has(.is--current) {
        position: relative;
        color: var(--bs-primary);

        &::before {
          @include abs(0, 0, null, 0);

          border-top: 3px solid currentColor;
          content: '';
        }
      }

      &__link {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &.is--current {
          color: var(--bs-primary);
        }
      }
    }
  }

  &__label {
    font-size: rem(11px);
    @include media-breakpoint-down(sm) {
      font-size: rem(10px);
    }
  }
}
