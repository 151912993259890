app-facility-map {
  display: contents;
}

.map {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  min-height: 300px;

  .static-map {
    // @include media-breakpoint-up(md) {
    @include abs(0);
    // }

    object-fit: cover;
    height: 100%;
  }
}

.facility-map__google-map {
  height: 100%;
  position: relative;
  background-color: gray-color(200);

  &__loading {
    @include abs(50%, null, null, 50%);
    transform: translate(-50%, -50%);
    opacity: 1;
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 700;
    color: $text-muted;
  }

  &__failure {
    @include abs(null, 60px, 23px, null);
    transform-origin: bottom right;
    transform: scale(0.8);
  }
  .gm-style .gm-style-iw-c {
    max-width: 300px !important;
  }

  .map-window .time-list {
    min-width: 200px;
    width: auto;
  }

  .time-list__item {
    padding-left: 0;
  }
}

.facility-map__google-map__alert {
  @include abs(auto, 10px, 15px, 10px);
  z-index: 2;
  pointer-events: none;

  @include media-breakpoint-up(md) {
    left: 30px;
    right: 30px;
    border-radius: 10px;
  }
}

.map-window {
  display: flex;
  flex-flow: column;
  font-size: 15px;
  align-items: flex-start;
  font-family: $font-family-sans-serif;

  @include media-breakpoint-up(md) {
    padding: 10px;
  }

  &__actions {
    width: 100%;
  }

  &__address {
    max-width: 260px;
  }

  .time-list {
    width: 260px;
    font-size: 12px;
  }
}
