.field-notification-time {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  &__container {
    display: flex;
    gap: spacer(3);
    align-items: center;
  }

  &__field {
    border: 1px solid var(--bs-secondary);
    border-radius: 10rem;
    overflow: hidden;
    position: relative;
    min-width: 120px;

    &:focus-within {
      outline: 1px solid var(--bs-secondary);
    }

    &__placeholder {
      @include abs(0);

      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--bs-secondary);
      font-weight: bold;
      background: white;

      .set--ios & {
        pointer-events: none;
      }
    }

    &__input {
      border: none;
      padding: 0;
      margin: 0;
      display: flex;
      font-family: inherit;
      font-weight: bold;
      min-width: 50px;
      padding: 0 15px;
      justify-content: center;
      align-items: center;
      height: 40px;
      text-align: center;
      outline: none;
      width: 100%;
      font-size: 1em;
      appearance: none;

      color: white;
      background: var(--bs-secondary);

      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        appearance: none;
      }
    }

    &::before {
      content: '';
      @include abs();
    }
  }
}
