.deposit-card {
  display: flex;
  padding: spacer(3);
  background-color: var(--bs-light);
  color: var(--bs-primary);
  gap: 1rem;
  min-height: 92px;
  align-items: center;
  position: relative;
  height: 100%;

  // MOET DIT? @berry..
  //.block-link {
  //  @include abs(0, 0, 0, 0);
  //  @include size(100%);
  //}

  &--primary {
    background-color: var(--bs-primary);
    color: var(--bs-light);
  }

  &--secondary {
    background-color: var(--bs-secondary);
    color: var(--bs-light);
  }

  &--secondary-light {
    background-color: var(--bs-secondary-100);
    color: var(--bs-secondary);
  }

  &--light {
    background-color: var(--bs-light);
  }

  &--primary-light {
    color: var(--bs-secondary);

    .deposit-card__ammount {
      color: var(--bs-primary);
    }
    .deposit-card__title {
      font-family: $font-family-sans-serif !important;
      font-weight: 700 !important;
    }
  }

  &__ammount {
    color: var(--bs-secondary);
    min-width: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .deposit-card--primary &,
    .deposit-card--secondary & {
      color: var(--bs-light);
    }
  }

  &__main {
    line-height: 1.4;

    .deposit-card--light & {
      color: var(--bs-secondary);
    }
  }

  &__title {
    margin-bottom: -0.5ex;
    color: var(--bs-secondary);

    .deposit-card--secondary & {
      color: var(--bs-primary);
    }

    .deposit-card--secondary-light & {
      color: var(--bs-primary);
    }
  }

  &--daily {
    flex-direction: column;
    color: var(--bs-secondary);
    gap: 0;
    align-items: flex-start;
  }
}
