fstr-icon {
  position: relative;
  display: inline-flex;
  min-width: 1em;
  min-height: 1em;

  .icon {
    @include abs(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    fill: inherit;
    margin: auto;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon--rotating .icon * {
  transform: rotate(0deg);
  animation: rotate 2s infinite linear;
  transform-origin: center;
}

@each $name, $color in $theme-colors {
  .icon-#{$name} {
    fill: $color;
  }
}
