// Bootstrap
$grid-breakpoints: (
  xxs: 0,
  xs: 376px,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px,
  xlg: 1440px,
  xxl: 1620px,
);

$grid-columns: 12;

$grid-gutter-width: 1.2rem;
$grid-gutter-width-sm: 0.8rem; // up to breakpoint sm
$grid-spacing: 40px;

$grid-row-columns: 6;

$gutters: $spacers;

// Other
//? Put own extra variables here
