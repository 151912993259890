$text-max-widths: (
  xxxs: 300px,
  xxs: 480px,
  xs: 550px,
  sm: 720px,
  md: 900px,
  lg: 1000px,
  xl: 1140px,
  xxl: 1320px,
);
