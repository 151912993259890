.loading-spinner {
  padding: spacer(2);
  display: flex;
  align-items: center;
  justify-content: center;

  fstr-icon {
    font-size: rem(30);
    margin-bottom: 0px;
  }
  .icon {
    fill: currentColor;
  }

  figcaption {
    transform: translateY(-5px);
  }
}
