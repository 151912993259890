@mixin button-icon() {
  @include size(rem($button-size));
  border-radius: rem(200px);
  display: inline-flex;
  justify-content: center;
  padding: 0;
  color: var(--button-color);
  min-width: rem($button-size);

  fstr-icon {
    margin-inline-start: 0 !important;
  }

  span + fstr-icon {
    margin-inline-end: 0;
  }
}

.button-icon {
  @include button-icon;
}
