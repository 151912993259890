.header {
  background-color: var(--bs-light);
  position: relative;
  z-index: 11;

  @include media-breakpoint-down(md) {
    z-index: 4;
    padding-top: 5px;
  }

  .header-cta-card {
    max-width: 600px;
  }

  &__inner__row {
    position: relative;
    z-index: 2;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex: 0 0 calc(100% - 20vw);
    }
  }

  &__inner {
    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      gap: spacer(3);
      //margin-bottom: spacer(4);
      // flex-direction: column;
    }
  }

  &__subtitle {
    @include media-breakpoint-down(md) {
      font-size: 1em;
    }
  }

  &__title {
    text-wrap: pretty;

    @include media-breakpoint-down(md) {
      font-size: calc(1.2rem + 1.8vw);
    }

    @include media-breakpoint-down(sm) {
      font-size: calc(1rem + 1vw);
    }
  }

  &__figure {
    @include media-breakpoint-down(md) {
      height: 120px;
      margin-left: auto;
      margin-right: 0;
      // width: 100%;
    }

    @include media-breakpoint-down(sm) {
      height: 100px;
    }
    --circle-cut-offset-factor: 0.5;
    --circle-cut-offset: calc(var(--circle-cut-offset-factor) * 100%);

    @include media-breakpoint-up(md) {
      --circle-cut-offset-factor: 0.5;
      --circle-cut-offset: calc(var(--circle-cut-offset-factor) * 100%);
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      aspect-ratio: calc(var(--circle-cut-offset-factor) + 1) / 1;
      height: 100%;
    }

    &__inner {
      border-radius: 100%;
      // @include media-breakpoint-down(md) {
      //   border-radius: 0%;
      //   width: 100%;
      // }
      overflow: hidden;
      height: 100%;
      aspect-ratio: 1/1;

      @include media-breakpoint-up(md) {
        transform: translate(0%, calc(var(--circle-cut-offset) * -0.5));
        height: calc(100% + var(--circle-cut-offset) * 1.5);
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;

        @include media-breakpoint-up(md) {
          height: calc(100% - var(--circle-cut-offset) / 1.5);
          width: auto;
          transform: translate(0%, calc(var(--circle-cut-offset) * 0.5));
          padding-top: spacer(5);
        }
        @include media-breakpoint-down(md) {
          border-radius: 5px;
        }
      }
    }
  }
}
