.story-list-card {
  $image-size-xs: 150px;
  $image-size-sm: 300px;

  display: flex;
  &.type--white {
    background-color: white;
  }

  &__meta {
    display: inline-block;
    line-height: 1.4;

    &__label {
      font-family: $headings-font-family;
      text-decoration: none;

      time + & {
        margin-inline-start: rem(5px);
      }
    }
  }

  .button-link {
    width: 100%;
    justify-content: start;
  }

  .tags {
    margin-left: rem(5px);
    display: inline;
    color: var(--bs-muted);

    &::before {
      content: '|';
      margin-right: rem(5px);
      color: $text-muted;
    }

    .tag {
      display: inline;

      @include media-breakpoint-down(md) {
        line-height: 1.3;
      }
    }
  }
  &__crop {
    margin-inline-end: rem(40px);
    max-width: 300px;

    @include media-breakpoint-down(md) {
      @include size(rem($image-size-sm));
      margin-inline-end: rem(20px);
    }

    @include media-breakpoint-down(sm) {
      @include size(rem($image-size-xs));
    }

    @include media-breakpoint-up(lg) {
      width: rem(960px);
    }
    @include media-breakpoint-down(xs) {
      @include size(100px);
    }

    * {
      width: 100%;
      height: 100%;
      display: inline-block;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
  }

  &.type--small {
    .story-list-card__content {
      width: 100%;
    }

    .story-list-card__meta,
    .button-link {
      font-size: 14px;
    }

    .story-list-card__title {
      font-family: $font-family-sans-serif;
      @include media-breakpoint-up(sm) {
        a {
          font-size: 20px;
        }
      }
    }

    .story-list-card__crop {
      margin-right: 0;
      margin-inline-end: rem(20px);
      align-self: stretch;
      display: flex;

      @include media-breakpoint-up(lg) {
        width: rem(230px);
      }

      @include media-breakpoint-down(md) {
        @include size(180px);
      }

      @include media-breakpoint-down(sm) {
        @include size(150px);
      }
      @include media-breakpoint-down(xs) {
        @include size(100px);
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 840px;
    padding: rem(20px);
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(md) {
      padding: 0 10px 0 0;
      width: calc(100% - #{rem($image-size-sm)});
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% - #{rem($image-size-xs)});

      .button {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      &__text {
        @include get-font-size(body-xxs);
      }
    }
  }

  &__title {
    @include media-breakpoint-down(sm) {
      // @include get-font-size(6);
    }

    a {
      width: 100%;
      font-family: $font-family-sans-serif;
    }
  }
}
