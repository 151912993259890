.nav-list-bar {
  display: flex;
  gap: 2px;
  width: 100%;
  align-items: center;

  .button {
    --button-padding-x: 20px;

    &:not(.is--active) {
      background: transparent;
      color: #1c1c1c;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--button-background);
        color: var(--button-color);
      }
    }
  }
}

.nav-list {
  a {
    color: white;
    text-decoration: none;
    font-weight: 600;
    padding: 5px 0;
    display: inline-block;

    &.is--active {
      color: var(--bs-primary);
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 6px 0;
    }
  }

  &--big a {
    @include get-font-size(2);
    font-weight: 800;
    padding: 2px 0;
  }
}
.nav-list-muted {
  padding-top: 1rem;
  &__item {
    font-style: italic;
    color: var(--bs-space-gray-400);

    fstr-link > a > span {
      font-style: italic;
      color: var(--bs-space-gray-400);
    }
  }
}
