.insight-overview {
  &__heading {
    border-top: solid 1px var(--bs-secondary);
    border-bottom: solid 1px var(--bs-secondary);
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.insights-list__subtitle:first-letter {
  text-transform: capitalize;
}
