.mat-mdc-form-field {
  --mdc-background-color: var(--bs-space-gray-100, whitesmoke); // not in docs, created by foster
  --mdc-bottom-border-color: var(--bs-space-gray-300);
}

@import './form-field';
@import './checkbox';
@import './form';

.app {
  @import './mat-slide-toggle';
}

.field-theme-light {
  --mdc-checkbox-disabled-selected-icon-color: rgb(255 255 255 / 38%);
  --mdc-checkbox-disabled-unselected-icon-color: rgb(255 255 255 / 38%);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--bs-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--bs-primary);
  --mdc-checkbox-selected-icon-color: var(--bs-primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--bs-primary);
  --mdc-checkbox-unselected-focus-icon-color: #ffffff;
  --mdc-checkbox-unselected-hover-icon-color: #ffffff;
  --mdc-checkbox-unselected-icon-color: rgb(255 255 255);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--bs-primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--bs-primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--bs-primary);
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: rgb(255 255 255 / 38%);

  --mdc-form-field-label-text-color: white;

  .mat-mdc-checkbox {
    --mdc-form-field-label-text-color: white;
  }
}
