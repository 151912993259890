.upcoming-order-list-item {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: 1rem;
    align-items: start;
  }
  &__image {
    img {
      @include size(75px);
      border-radius: 100%;
    }
  }
}
