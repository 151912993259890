.markdown-content {
  $grid-column-outset: 100px; // is used to expand x pixels out of the container
  $markdown-font-sizes: (
    h1: 38px,
    h2: 28px,
    h3: 22px,
    h4: 18px,
  );

  iframe {
    width: 100%;
    min-height: 330px;
  }

  color: gray-color(700);

  .fr-img-wrap {
    display: flex;
    margin-bottom: 1em;
    flex-flow: column;
    align-items: flex-end;
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 4);

    .fr-inner {
      @include get-font-size(body-xxs);
      font-weight: 600;
      color: space-gray-color(500);
      padding-top: map-get($spacers, 2);
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  @for $i from 1 through 4 {
    .h#{$i},
    h#{$i} {
      font-family: $font-family-sans-serif;
      color: $black;
    }
  }

  p {
    margin-bottom: 1em;
    line-height: 200%;
  }

  img {
    max-width: 100%;
  }

  u {
    color: $black;
  }

  em,
  i {
    font-style: italic;
    color: $black;
  }

  // markdown content heading styles
  @each $name, $size in $markdown-font-sizes {
    #{$name},
    .#{$name} {
      font-size: rem($size);
      color: $black;
      font-family: $font-family-sans-serif;
      margin-top: 1em;
    }
  }

  blockquote {
    padding: map-get($spacers, 6) map-get($spacers, 8) map-get($spacers, 4) map-get($spacers, 8);
    position: relative;
    color: $black;

    @include media-breakpoint-down(md) {
      padding: map-get($spacers, 4) map-get($spacers, 6) map-get($spacers, 3) map-get($spacers, 6);
    }

    &::before {
      @include abs(22px, null, null, 32px);
      content: '';
      background-image: url('/assets/gfx/quote-mark.svg');
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      z-index: -1;

      @include media-breakpoint-down(md) {
        top: 12px;
        left: 15px;
      }
    }

    &,
    * {
      @include get-font-size(body-md);
      font-weight: 600;
    }
  }

  ul,
  ol {
    list-style: outside;
    padding-inline-start: map-get($spacers, 4);
    margin-bottom: 1.2em;

    li {
      display: list-item;
      list-style-type: initial;
    }
  }

  ol li {
    list-style-type: decimal-leading-zero;
  }

  .fr-video {
    display: block;
    width: 100%;
    margin-bottom: map-get($spacers, 4);

    &:empty {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      display: inline-block;
      position: relative;
      padding: 50px 0 0 0;
      overflow: visible;
      max-width: 100%;
      // margin: rem(20px) -#{$grid-column-outset / 2} 0;
      width: 100%;
    }

    @extend .aspect-ratio;

    iframe {
      @extend .aspect-ratio-element;
      z-index: 2;

      @include media-breakpoint-up(md) {
        padding: 50px 0;
      }
    }

    &:after {
      content: '';
      background: space-gray-color(100);
      position: absolute;
      left: -100vw;
      right: -100vw;
      top: 0;
      bottom: 20%;
      z-index: 0;
    }
  }

  &.markdown-content--simple {
    table {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .fr-video {
      margin: 0;
      width: auto;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      display: block;

      &::before,
      &::after {
        display: none;
      }

      iframe {
        @include abs(0);
        padding: 0;
      }
    }
  }
}

.markdown-content {
  iframe#contact-chat {
    height: 1080px;
  }
}
